const firebaseConfig = {
  apiKey: "AIzaSyDBuiY7injma49G6T7cW0Ft25CAEpCh4Dg",
  authDomain: "dssystem-fb08e.firebaseapp.com",
  projectId: "dssystem-fb08e",
  storageBucket: "dssystem-fb08e.appspot.com",
  messagingSenderId: "305958858529",
  appId: "1:305958858529:web:67c84cef3ea9ef119b5860"
};

export default firebaseConfig;
